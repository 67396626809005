/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useThree, useFrame } from '@react-three/fiber'
import { easing } from 'maath'
import { degToRad } from 'maath/misc'
import { useRoute } from 'wouter'
import * as THREE from 'three'
import useMyCursor from '../../Stores/useCursor'

//head follow cam, use only for smoothing
let lookPosition = new THREE.Vector2()

//https://tympanus.net/codrops/2019/10/14/how-to-create-an-interactive-3d-character-with-three-js/
const moveJoint = (
	mouse,
	joint,
	degreeLimit,
	rotxOffset = 0,
	rotyOffset = 0
) => {
	let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit)
	joint.rotation.y = degToRad(degrees.x - 1 + rotyOffset)
	joint.rotation.x = degToRad(degrees.y + 20 + rotxOffset)
}

const getMouseDegrees = (x, y, degreeLimit) => {
	let dx = 0,
		dy = 0,
		xdiff,
		xPercentage,
		ydiff,
		yPercentage

	let w = { x: window.innerWidth, y: window.innerHeight }

	// Left (Rotates neck left between 0 and -degreeLimit)

	// 1. If cursor is in the left half of screen
	if (x <= w.x / 2) {
		// 2. Get the difference between middle of screen and cursor position
		xdiff = w.x / 2 - x
		// 3. Find the percentage of that difference (percentage toward edge of screen)
		xPercentage = (xdiff / (w.x / 2)) * 100
		// 4. Convert that to a percentage of the maximum rotation we allow for the neck
		dx = ((degreeLimit * xPercentage) / 100) * -1
	}
	// Right (Rotates neck right between 0 and degreeLimit)
	if (x >= w.x / 2) {
		xdiff = x - w.x / 2
		xPercentage = (xdiff / (w.x / 2)) * 100
		dx = (degreeLimit * xPercentage) / 100
	}
	// Up (Rotates neck up between 0 and -degreeLimit)
	if (y <= w.y / 2) {
		ydiff = w.y / 2 - y
		yPercentage = (ydiff / (w.y / 2)) * 100
		// Note that I cut degreeLimit in half when she looks up
		dy = ((degreeLimit * 0.5 * yPercentage) / 100) * -1
	}

	// Down (Rotates neck down between 0 and degreeLimit)
	if (y >= w.y / 2) {
		ydiff = y - w.y / 2
		yPercentage = (ydiff / (w.y / 2)) * 100
		dy = (degreeLimit * yPercentage) / 100
	}
	return { x: dx, y: dy }
}

let emotes = []
// alwats set this to initial anim
let lastEmoteName = 'swag'
export default function Model(props) {
	const [, params] = useRoute('/:link')
	const group = useRef()
	const { nodes, materials, animations } = useGLTF('/me.glb')
	const { actions, mixer, clips } = useAnimations(animations, group)
	const [shouldTurnToCam, setShouldTurnToCam] = useState(true)
	const cursorPos = useMyCursor((state) => state.position)
	const [shouldFollowCursor, setShouldFollowCursor] = useState(true)
	const camera = useThree((state) => state.camera)

	///////////////////mouse hover/////////////////////////
	const [hovered, setHovered] = useState(false)
	useEffect(() => {
		document.body.style.cursor = hovered ? 'pointer' : 'auto'
	}, [hovered])
	//////////////////////////////////////////////////////
	//////////////////////////////////////////////////animations ///////////////////////////////////////////////////////
	const [currentAction, setCurrentAction] = useState(null)
	const [shouldPlayEmote, setShouldPlayEmote] = useState(false)
	function fadeToAction(action, duration = 0.2) {
		const previousAction = currentAction
		setCurrentAction(action)

		if (previousAction !== action) {
			previousAction.fadeOut(duration)
		}

		action
			.reset()
			.setEffectiveTimeScale(1)
			.setEffectiveWeight(1)
			.fadeIn(duration)
			.play()
	}

	function playMontage(action) {
		// console.log('play')
		setShouldPlayEmote(false)
		fadeToAction(action, 0.2)

		mixer.addEventListener('finished', restoreState)
	}
	function restoreState(e) {
		mixer.removeEventListener('finished', restoreState)
		fadeToAction(actions.idle, 0.5)
		setTimeout(() => {
			setShouldPlayEmote(true)
		}, 500)
	}

	//actions
	useEffect(() => {
		//add some effects
		// console.log('action change:' + currentAction?._clip.name)
	}, [currentAction])

	const playEmote = () => {
		if (!shouldPlayEmote) return

		//////////////////// prevent last emote affecting to new emote
		const cachedEmoteName = lastEmoteName
		const ranEmote = emotes[Math.floor(Math.random() * emotes.length)]
		lastEmoteName = ranEmote._clip.name

		if (cachedEmoteName && cachedEmoteName != lastEmoteName) {
			// console.log(cachedEmoteName, lastEmoteName)
			actions[cachedEmoteName].setEffectiveWeight(0)
		}
		////////////////////

		playMontage(ranEmote)
	}
	////////////////////////////////////////////// Tick ////////////////////////////////////////////////////////////////
	useFrame((state, dt) => {
		if (params && params.link === 'me') {
			setShouldFollowCursor(false)
			if (camera) {
				if (shouldTurnToCam) {
					// .3,.3,0 come from experiment
					easing.dampE(group.current.rotation, [0.3, 0.3, 0], 0.15, dt, 1)
					// dampE doesn't work on rotation so do this, but this also cause bone snap
					// easing.damp(nodes.Neck.rotation, 'x', degToRad(15), 1, dt, 1)
					// easing.damp(nodes.Neck.rotation, 'y', degToRad(0), 1, dt, 1)
					// easing.damp(nodes.Spine1.rotation, 'x', degToRad(10), 1, dt, 1)
					// easing.damp(nodes.Spine1.rotation, 'y', degToRad(0), 1, dt, 1)
					// easing.damp()
					const cahcedLookPosition = lookPosition.clone()
					easing.damp2(
						lookPosition,
						[window.innerWidth / 2, window.innerHeight / 5],
						0.15,
						dt,
						2000
					)

					moveJoint(lookPosition, nodes.Spine1, 25, -12, 0)
					moveJoint(lookPosition, nodes.Neck, 35, 3, 5)

					// console.log('rotating')
					//fire once when entering
					if (cahcedLookPosition.equals(lookPosition)) {
						setShouldTurnToCam(false)
						emotes.forEach((a) => a.setEffectiveWeight(0))
						lastEmoteName = 'swag'
						playMontage(actions.swag, 1.5)
					}
				}
			}
		} else {
			// so it turns back to 0 rot
			easing.dampE(group.current.rotation, [0, 0, 0], 0.4, dt, 1)
			setShouldTurnToCam(true)
			setShouldFollowCursor(true)
			setShouldPlayEmote(false)
			// console.log(group.current.rotation)
		}
		// console.log(group.current.rotation)
		if (shouldFollowCursor) {
			easing.damp2(lookPosition, [cursorPos.x, cursorPos.y], 0.05, dt, 2000)
			moveJoint(lookPosition, nodes.Neck, 35)
			moveJoint(lookPosition, nodes.Spine1, 25)
		}
	})

	//init
	useEffect(() => {
		Object.keys(actions).forEach((key, idx) => {
			if (idx != 0) {
				actions[key].loop = THREE.LoopOnce
				actions[key].clampWhenFinished = true
				actions[key].setEffectiveWeight(0)

				if (key != 'stretch') emotes.push(actions[key])
			}
		})
		actions.idle.reset().play()
		setCurrentAction(actions.idle)
		// console.log(emotes)
	}, [])
	// console.log('render')d
	return (
		<group
			ref={group}
			{...props}
			dispose={null}
			onClick={playEmote}
			onPointerEnter={(e) => setHovered(true)}
			onPointerLeave={(e) => setHovered(false)}
		>
			<group name='Scene' position={[0, -2.1, -0.1]} scale={1.5}>
				<group name='character'>
					<skinnedMesh
						name='EyeLeft'
						geometry={nodes.EyeLeft.geometry}
						material={materials['Wolf3D_Eye.006']}
						skeleton={nodes.EyeLeft.skeleton}
						morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
						morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
					/>
					<skinnedMesh
						name='EyeRight'
						geometry={nodes.EyeRight.geometry}
						material={materials['Wolf3D_Eye.006']}
						skeleton={nodes.EyeRight.skeleton}
						morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
						morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
					/>
					<skinnedMesh
						castShadow
						name='Body'
						geometry={nodes.Wolf3D_Body.geometry}
						material={materials['Wolf3D_Body.006']}
						skeleton={nodes.Wolf3D_Body.skeleton}
					/>
					<skinnedMesh
						castShadow
						name='Hair'
						geometry={nodes.Wolf3D_Hair.geometry}
						material={materials['Wolf3D_Hair.006']}
						skeleton={nodes.Wolf3D_Hair.skeleton}
					/>
					<skinnedMesh
						castShadow
						name='Head'
						geometry={nodes.Wolf3D_Head.geometry}
						material={materials['Wolf3D_Skin.006']}
						skeleton={nodes.Wolf3D_Head.skeleton}
						morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
						morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
					/>
					<skinnedMesh
						castShadow
						name='Outfit_Bottom'
						geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
						material={materials['Wolf3D_Outfit_Bottom.006']}
						skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
					/>
					<skinnedMesh
						castShadow
						name='Outfit_Footwear'
						geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
						material={materials['Wolf3D_Outfit_Footwear.006']}
						skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
					/>
					<skinnedMesh
						castShadow
						name='Outfit_Top'
						geometry={nodes.Wolf3D_Outfit_Top.geometry}
						material={materials['Wolf3D_Outfit_Top.006']}
						skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
					/>
					<skinnedMesh
						name='Teeth'
						geometry={nodes.Wolf3D_Teeth.geometry}
						material={materials['Wolf3D_Teeth.006']}
						skeleton={nodes.Wolf3D_Teeth.skeleton}
						morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
						morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
					/>
					<primitive object={nodes.Hips} />
				</group>
			</group>
		</group>
	)
}

useGLTF.preload('/me.glb')
